<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <div class="shadow overflow-hidden sm:rounded-md px-4 py-5 bg-white sm:p-6 mt-3">
      <div class="grid grid-cols-12 mb-4">
        <div class="col-span-12 md:col-span-6">
          <h1 class="md:float-left text-2xl">Informações Gerais</h1>
          <a class="md:float-right underline" :href="`${usaWhatsOficial() ? '/arquivo-modelo-lembrazap-campanha_w4b.xlsx' : '/arquivo-modelo-lembrazap-campanha.xlsx'}`"> Download arquivo modelo importação </a>
        </div>
      </div>
      <p v-if="$store.state.user && $store.state.user.lzv2 && !$store.state.user.lzv2.cliente_novavida" class="text-sm_3 text-red-500 my-2">Uploads feito até o horário de 10:30 são <b>priorizados</b>, campanhas posteriores são sujeitas a fila.<br/> A média de tempo para desempenho de retorno das ações é 2h a 3h</p>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 md:col-span-6">
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12">
              <label for="name" class="block text-sm font-medium text-gray-700">Nome*</label>
              <input required v-model="form.nome" type="text" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-5" v-if="$store.state.user.admin">
              <label for="centroscusto" class="block text-sm font-medium text-gray-700">Centro de custo</label>
              <v-select v-model="form.centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
            </div>
          </div>

          <label for="dataDisparo" class="mt-2 block text-sm font-medium text-gray-700">Data hora disparo*</label>

          <div class="grid grid-cols-12 gap-2">
            <div class="col-span-12 md:col-span-5">
              <input v-model="form.dataDisparo" type="date" id="dataDisparo" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-3">
              <input v-model="form.horaDisparo" type="time" id="horaDisparo" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12" v-if="!usaWhatsOficial()">
              <label for="editMSg" class="my-2 block text-sm font-medium text-gray-700 md:w-1/3 md:cursor-pointer">
                <input type="checkbox" v-model="form.editarMensagemManual" class="rounded-sm" id="editMSg" />
                <span class="ml-2">Editar mensagem manualmente </span>
              </label>
              <textarea
                rows="3"
                v-if="form.editarMensagemManual"
                @input="updateMensagemEditada()"
                v-model="form.mensagemEditada"
                id="obs"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div class="col-span-12">
              <label for="doc" class="mt-2 block text-sm font-medium text-gray-700">Arquivo para importar</label>
              <vue-dropzone
                required
                ref="doc"
                id="doc"
                class="w-full"
                :options="dropzoneCampaignOptions"
                @vdropzone-sending="senPreview"
                v-on:vdropzone-success="updateCampaign"
                v-on:vdropzone-removed-file="removeFileCampaign"
              >
              </vue-dropzone>
            </div>
            <div class="col-span-12" v-if="usaWhatsOficial()">
              <label for="numeroWhatsAppOficial" class="mt-1 block text-sm font-medium text-gray-700 md:w-1/3 md:cursor-pointer">Selecione um <b>número</b> para o envio</label>
              <v-select v-model="form.numeroWhatsAppOficial" :options="numeros" label="telefone" @input="filtraTemplatesPorNumero" name="numeroWhatsAppOficial" id="numeroWhatsAppOficial" class="mt-1"></v-select>
            </div>
            <div class="col-span-12 mb-4" v-if="usaWhatsOficial() && form.numeroWhatsAppOficial">
              <label for="template" class="mt-1 block text-sm font-medium text-gray-700 md:w-1/3 md:cursor-pointer">Selecione um <b>template</b> para o envio</label>
              <v-select v-model="form.template" :options="templates" @input="selectTemplate" label="nome" name="template" id="centrocusto" class="mt-1"></v-select>
              <div v-if="form.vartemplate" class="grid grid-cols-12 gap-2">
                <div v-for="(item, idx) in form.vartemplate" :key="idx" class="mt-2 col-span-12 md:col-span-4">
                  <label class="mt-1 block text-sm font-medium text-gray-700">Variável {{ idx + 1 }}</label>
                  <select
                    v-model="item.key"
                    @change="substituirExemplo(item.key, idx)"
                    class="py-1 px-2 h-11 w-full border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option v-for="(key, k) in keys" :key="k" :value="key">{{ key }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="$store.state.user.operador && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('chats') !== -1" class="col-span-12">
              <label for="salvarOperador" class="my-2 block text-sm font-medium text-gray-700">
                <input type="checkbox" v-model="form.salvarOperador" class="rounded-sm" id="salvarOperador" />
                <span class="ml-2"> Retornos exclusivos do operador que subiu a campanha </span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6">
          <div v-if="($store.state.user.lzv2.liberaEnvioAnexo && !usaWhatsOficial()) || (form.template && form.template.header && form.template.header.format !== 'TEXT')" class="grid grid-cols-12 gap-3 mb-2">
            <div class="col-span-6">
              <label for="arquivo" class="block text-sm font-medium text-gray-700">Enviar um anexo</label>
              <small class="text-sm my-1">Utilize essa opção quando precisar enviar uma imagem, catalogo ou documento junto com a mensagem.</small>
              <vue-dropzone
                required
                :key="keyDropZone"
                ref="arquivo"
                id="arquivo"
                class="w-full"
                @vdropzone-sending="send"
                :options="dropzoneAttachmenOptions"
                v-on:vdropzone-success="updateArquivo"
                v-on:vdropzone-removed-file="removeArquivo"
              >
              </vue-dropzone>
            </div>
            <div v-if="(form.arquivo && form.arquivo.mimetype === 'image/jpeg' && !usaWhatsOficial()) || (form.arquivo && form.arquivo.mimetype === 'image/png' && !usaWhatsOficial())" class="col-span-6">
              <label for="arquivo" class="mt-2 block text-sm font-medium text-gray-700 mb-1">Arquivo</label>
              <img class="w-full" :src="`${api}/upload?mimetype=${form.arquivo.mimetype}&filename=${form.arquivo.filename}&folder=arquivosWhats`" />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12">
              <label for="obs" class="block text-sm font-medium text-gray-700">Observação</label>
              <textarea rows="3" v-model="form.obs" id="obs" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>
            <div class="col-span-12">
              <label for="tag" class="block text-sm font-medium text-gray-700">Etiqueta / Tabulação</label>
              <multiselect
                v-model="form.tags"
                :options="tags"
                :multiple="true"
                :close-on-select="false"
                :taggable="true"
                label="nome"
                trackBy="_id"
                id="tags"
                placeholder="selecione as etiquetas"
                selectedLabel="selecionado"
                deselectLabel="Pressione Enter para remover"
                selectLabel="Pressione Enter para selecionar"
                class="mt-2"
              />
            </div>
            <div v-if="usaWhatsOficial()" class="col-span-12 my-3">
              <Preview
                v-if="form.template"
                :image="form.template.header.example"
                :document="form.template.header.filename"
                :edit="true"
                :nome="form.template.nome"
                :header="form.template.header.text"
                :examples="form.template.body.examples"
                :body="form.template.body.text"
                :footer="form.template.footer.text"
                :buttons="form.template.buttons"
              />
            </div>
          </div>
        </div>

        <div v-if="!usaWhatsOficial()" class="col-span-12">
          <div class="grid grid-cols-2 mb-3">
            <h1 class="text-xl font-semibold">Prévia</h1>
            <small class="text-right">{{ previews.length }} Mensagens </small>
          </div>
          <label for="editVariaveis" v-if="!usaWhatsOficial()" class="my-2 block text-sm font-medium text-gray-700 md:w-1/3 md:cursor-pointer">
            <input type="checkbox" v-model="editVariaveis" class="rounded-sm" id="editVariaveis" />
            <span class="ml-2"> Editar Variáveis </span>
          </label>
          <div v-if="editVariaveis && previews[0] && previews[0].mensagem" class="grid grid-cols-12 gap-6 mb-3">
            <div class="col-span-12 md:col-span-6" @contextmenu.prevent="openContextMenu">
              <p class="bg-gray-200 rounded-md text-sm px-3 py-2" v-html="getHtmlFrom(previews[0].mensagem)"></p>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div v-for="(s, idx) in updateKeys" :key="idx" class="px-2 py-3 text-base text-gray-900 border-b-2">
                <span class="text-left">
                  {{ s.old_key }}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="inline mx-3 w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                  </svg>
                  {{ s.new_key }}
                </span>
                <svg
                  @click="removerSubstituicao(s, idx)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="cursor-pointer float-right text-red-700 w-6 h-6"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
            <context-menu :display="showContextMenu" ref="menu">
              <ul>
                <li v-for="(key, idx) in keys" :key="idx" @click="substituir(key)" class="px-2 py-2 text-base text-gray-900 border-b-2 hover:bg-gray-800 hover:text-white">{{ key }}</li>
              </ul>
            </context-menu>
          </div>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CONTATOS</th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MENSAGENS</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="preview in previews" :key="preview.contato.nome">
                  <td class="px-3 py-3 text-sm whitespace-nowrap">
                    {{ preview.contato.Nome ? `${preview.contato.Nome} - ${preview.contato.telefone} ` : preview.contato.telefone }}
                  </td>
                  <td class="px-3 py-3 text-sm" v-html="getHtmlFrom(preview.mensagem)"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="fixed z-50 top-80 left-0 w-full text-center text-lg text-zinc-600 font-bold">
        <span class="text-green-600">{{ progresso }}</span> / 100%
      </div>
      <div class="grid grid-cols-2">
        <div class="py-3 bg-gray-50 text-left sm:px-6">
          <router-link
            :to="`/campanhas`"
            class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {{ $t("txt-back") }}
          </router-link>
        </div>
        <div class="py-3 bg-gray-50 text-right sm:px-6">
          <button
            @click="save"
            type="button"
            :disabled="bloquearPorLimite"
            :class="{ 'opacity-50': bloquearPorLimite }"
            class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {{ $t("txt-save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Breadcrumb from "@/components/Breadcrumbs.vue";
import ContextMenu from "@/components/ContextMenu";
import Preview from "../templates/components/Preview.vue";

export default {
  components: {
    ContextMenu,
    vueDropzone,
    Breadcrumb,
    Preview
  },
  data() {
    return {
      isLoading: false,
      progresso: 0,
      breadcrumb: [
        { url: "/campanhas", value: "Campanhas" },
        { url: "/campanhas/import", value: "Importar" }
      ],
      route: "campaign",
      partitionCampaign: false,
      moment: moment,
      previews: [],
      keys: [],
      old_key: "",
      updateKeys: [],
      showContextMenu: false,
      centroscusto: [],
      zaps: [],
      tags: [],
      shootingDate: moment().toISOString(),
      shootingTime: moment().add(40, "m").format("HH:mm"),
      editVariaveis: false,
      editVariavelTemplate: false,
      exemploOutput: ``,
      form: {
        nome: "",
        dataDisparo: "",
        horaDisparo: "",
        file: "",
        dataHoraDisparo: "",
        metodo: "",
        obs: "",
        tags: [],
        centrocusto: null,
        salvarOperador: false,
        arquivo: null,
        editarMensagemManual: false,
        mensagemEditada: "",
        template: null,
        numeroWhatsAppOficial: null,
        vartemplate: []
      },
      bloquearPorLimite: false,
      templates: [],
      numeros: [],
      keyDropZone: 0,
      totalDeContatos: 0,
      dropzoneBody: {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: "Clique ou arraste seu anexo aqui",
        dictRemoveFile: "Remover"
      },
      dropzoneAttachmenOptions: this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBody }),
      dropzoneCampaignOptions: this.$http.getDropzoneConfig(`campanhasLZV2/preview-campanha-import`, "post", {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: `Clique ou arraste o arquivo da campanha aqui`,
        dictRemoveFile: "Remover"
      })
    };
  },
  mounted() {
    this.sockets.subscribe(`progressao_${this.$store.state.user._id}`, (progresso) => {
      this.progresso = progresso;
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe(`progressao_${this.$store.state.user._id}`);
  },
  methods: {
    usaWhatsOficial() {
      return this.$store.state.user.acessos_lzv2 && this.$store.state.user.acessos_lzv2.indexOf("whatsappApiOficial") !== -1;
    },
    selectTemplate() {
      this.form.vartemplate = [];
      for (let i = 0; i < this.form.template.body.placeholders; i++) {
        this.form.vartemplate.push({ key: "" });
      }
    },
    update(file, response) {
      this.form.arquivo = response.file;
    },
    updateArquivo(file, response) {
      this.form.arquivo = response.file;
    },
    send(file, xhr, formData) {
      formData.append("folder", "arquivosWhats");
      if (this.form.template && this.form.template.header && this.form.template.header.filename) this.form.template.header.filename = file.name;
    },
    senPreview(file, xhr, formData) {
      if (this.form.editarMensagemManual) formData.append("mensagemEditada", this.form.mensagemEditada);
      if (this.form.template) formData.append("template", this.form.template);
    },
    updateCampaign(file, response) {
      this.totalDeContatos = response.totalContatos;
      this.form.file = file;
      this.previews = response.data;
      this.keys = Object.keys(response.data[0].contato);
      if (response.data[0] && response.data[0].mensagem) this.form.mensagemEditada = response.data[0].mensagem;
    },
    removeArquivo() {
      this.form.arquivo = null;
    },
    removeFileCampaign() {
      this.form.file = null;
      this.previews = [];
      this.keys = [];
    },
    async filtraTemplatesPorNumero() {
      if (this.form.numeroWhatsAppOficial === null) {
        this.templates = [];
        return;
      }

      const responseTemplates = await this.$http.post("/v1/templates/1/list", { ativo: true, status: "Aprovado", numero: this.form.numeroWhatsAppOficial._id });
      this.templates = responseTemplates.data.list;
    },
    async save() {
      if (this.bloquearPorLimite) {
        return;
      }

      this.isLoading = true;
      const loader = this.$loading.show({
        container: null,
        canCancel: true
      });

      if (this.$store.state.user.lzv2.centrocusto && !this.form.centrocusto) {
        loader.hide();
        this.isLoading = false;
        return this.$vToastify.error("Selecione o centro de custo");
      }

      const dataEnvio = new FormData();

      const dataHoraDisparo = moment(`${this.form.dataDisparo} ${this.form.horaDisparo}`, "YYYY-MM-DD HH:mm").toDate();

      dataEnvio.append("file", this.form.file);
      dataEnvio.append("arquivo", JSON.stringify(this.form.arquivo));
      dataEnvio.append("dataHoraDisparo", dataHoraDisparo);
      dataEnvio.append("nome", this.form.nome);
      dataEnvio.append("updateKeys", JSON.stringify(this.updateKeys));
      dataEnvio.append("tags", JSON.stringify(this.form.tags));

      if (this.form.editarMensagemManual) dataEnvio.append("mensagemEditada", this.form.mensagemEditada);

      if (this.form.centrocusto) {
        dataEnvio.append("centrocusto", this.form.centrocusto);
      }

      if (this.form.salvarOperador) {
        dataEnvio.append("salvarOperador", this.form.salvarOperador);
      }

      if (this.usaWhatsOficial()) {
        if (!this.form.template || (this.form.vartemplate.length && this.form.vartemplate.every((objeto) => Object.values(objeto).every((valor) => valor.trim() === "")))) {
          this.isLoading = false;
          loader.hide();
          return this.$vToastify.error("Selecione um template para envio e suas variáveis caso existente!");
        }

        dataEnvio.append("template", JSON.stringify(this.form.template));
        dataEnvio.append("vartemplate", JSON.stringify(this.form.vartemplate));
        dataEnvio.append("numeroWhatsAppOficial", this.form.numeroWhatsAppOficial.telefone);
      }

      const result = await this.$http.post("/v1/campanhasLZV2/gerar-campanha-import", dataEnvio);

      this.isLoading = false;
      loader.hide();

      if (result.data.success) {
        this.$vToastify.success("Sucesso ao importar campanha");
        this.$router.push({ path: "/campanhas" });
        this.$store.commit("updateSaldoPrePago", result.data.saldoPrePago);
      } else {
        this.$vToastify.error(result.data.err);
      }
    },
    updateMensagemEditada() {
      for (let i = 0; i < this.previews.length; i++) {
        const p = this.previews[i];
        p.mensagem = this.form.mensagemEditada;
      }
    },
    removerSubstituicao(updateKey, idx) {
      for (let i = 0; i < this.previews.length; i++) {
        const p = this.previews[i];
        p.mensagem = p.mensagem.replace(p.contato[updateKey.new_key], updateKey.old_key);
      }
      this.updateKeys.splice(idx, 1);
    },
    substituirExemplo(key, idx) {
      const p = this.previews[0];
      this.editVariavelTemplate = true;
      this.$set(this.form.template.body.examples, idx, p.contato[key]);
    },
    substituir(key) {
      this.updateKeys.push({ old_key: this.old_key, new_key: key });
      for (let i = 0; i < this.previews.length; i++) {
        const p = this.previews[i];
        p.mensagem = p.mensagem.replace(this.old_key, p.contato[key]);
      }
    },
    openContextMenu(e) {
      this.old_key = window.getSelection().toString();

      if (this.old_key.trim() === "") {
        this.old_key = null;
        return this.$vToastify.error("A seleção da váriavel não pode ser um espaço vazio!");
      }

      this.$refs.menu.open(e);
    },
    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/gim, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      }

      return str;
    }
  },
  async beforeMount() {
    this.form.nome = `${moment().format("x")}`;
    this.form.dataDisparo = moment().format("YYYY-MM-DD");
    this.form.horaDisparo = moment().add(40, "m").format("HH:mm");
    this.form.ignoraTrava = true;

    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto && !this.$store.state.user.admin) {
      this.form.centrocusto = this.$store.state.user.centrocusto;
    }

    const tagReq = await this.$http.post("/v1/tag/list", { all: true });
    this.tags = tagReq.data.data;

    const responseNumeros = await this.$http.post("/v1/numeros/list", { ativo: true });
    this.numeros = responseNumeros.data.list;
  },
  watch: {
    "form.template": {
      handler(valor) {
        if (valor !== null) {
          if (this.form.template.header.format === "DOCUMENT") {
            this.dropzoneBody.acceptedFiles = ".pdf,.DOCX";
          }

          if (this.form.template.header.format === "IMAGE") {
            this.dropzoneBody.acceptedFiles = ".png,.jpg";
          }

          this.dropzoneAttachmenOptions = this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBody });
          this.keyDropZone++;
        }
      }
    },
    "form.numeroWhatsAppOficial": {
      handler(valor) {
        if (valor === null) {
          return;
        }

        const limiteDisponivel = this.form.numeroWhatsAppOficial.limiteDeEnvio - this.form.numeroWhatsAppOficial.quantidadeEnviada;

        if (this.previews && limiteDisponivel < this.totalDeContatos) {
          this.bloquearPorLimite = true;
          return this.$vToastify.error(`Quantidade a ser enviada (${this.totalDeContatos}) é maior que o limite disponível (${limiteDisponivel})`);
        }

        this.bloquearPorLimite = false;
      },
      deep: true
    }
  }
};
</script>
